<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>	
					<el-button type="success" @click="addnew">新增</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">
								详情
							</el-button>	
							<el-button v-show="scope.row.status==0"
								@click="editrow(scope.row)" type="warning" size="mini">
								编辑
							</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<detail ref="mydetail"></detail>
	</div>
</template>

<script>	
import detail from './detail.vue'
	export default{		
		components:{ detail },
		data() {
			return{
				showqj:false,
				qjliyou: '',
				user_id: '',
				user_name: '',
				qjid:'',
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'name',
						name: '履职报告名称',
						width: ''
					},
					{
						prop: 'status',
						name: '状态',
						width: '120',
						type:'lvbg_status'
					},
					{
						prop: 'createtime',
						name: '上传时间',
						width: '200'
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			showeidt(row) {
				this.$post({
					url: '/api/user_resumption_report/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					console.log(res)
					this.$refs.mydetail.detail = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			editrow(row){
				this.$router.push({path:'/system/lvzhi_edit',query:{id:row.id}})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/user_resumption_report/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {					
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew(){
				this.$router.push('/system/lvzhi_add')
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'bg_lvzhi.scss';
</style>